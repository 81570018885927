

import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted, PropType, ref, toRefs,
} from "vue";

import { useStore } from "vuex";
import SvgIcon from "@/components/SvgIcon";
import Draggable from "vuedraggable";
//import TicketCard from "@/components/TicketCard/TicketCard.vue";
import { IStep } from "@/types";
import NodeIntersect from "@/directives/NodeIntersect";
//import TheLazy from "@/components/TheStep/TheLazy.vue";
import { Tippy } from "vue-tippy";
//import { useElementHover } from "@/composables/useElementHover";
import { useI18n } from "vue-i18n";
import TheQuickAdd from "@/components/TheQuickAdd/TheQuickAdd.vue";
import StepTicket from "@/components/TheStep/StepTicket2.vue"
import MenuArchivedTickets from "@/components/Menus/MenuArchivedTickets.vue"
import {useFloatingExists} from "@/composables/useFloatingExists"
import { autorefreshBlockAdd, autorefreshBlockRemove } from "@/composables/useFetchState"

export default defineComponent({
  name: "the-step",

  props: {
    step: {
      type: Object as PropType<IStep>,
      required: true
    },
    isEmpty: { type: Boolean, default: false },
    projectId: {type: String, required: true},
    realTicketsCount: { type: Number, default: 0 },
    expanded: {
      type: Boolean
    },
    isLazyOn: String,
    stepIndex: Number,
    isLastStep: { type: Boolean, default: false },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ticketViewSettings: Object as PropType<any>,
    //isDragInProcess: { type: Boolean },
    quickAddStepId: String,
  },

  emits: ['set-expand', 'start', 'end', 'order-update', 'quick-add', 'ticketMenu'],

  directives: {
    NodeIntersect
  },

  components: {
    Tippy,
    // TheLazy,
    // TicketCard,
    SvgIcon,
    Draggable,
    TheQuickAdd,
    StepTicket,
    MenuArchivedTickets,
},

  setup(props, { emit }) {

    const store = useStore();
    const { step, expanded } = toRefs(props);

    const {t} = useI18n();

    const archived_count = computed(() => store.getters["board/getArchivedCount"]);

    const refArchivedMenuButton = ref(null)
    const refArchivedMenu = ref<InstanceType<typeof MenuArchivedTickets> | null>(null)
    const {isExist: isExistArchivedMenu, triggerHandler: triggerHandlerArchivedMenu} = useFloatingExists(refArchivedMenu)

    const tList = computed({
      get() {
        if (expanded.value) {
          //return store.getters["board/getTicketsByStep"](step.value.id);
          return store.getters["board/getTicketsByStep2"](step.value.id, 4000);
        } else {
          return [];
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set(value: any[]) {
        if (expanded.value) {
          store.commit('board/updateList', { id: step.value.id, tickets: value });
        } else {
          store.commit('board/add_TicketIdToStep', { stepId: step.value.id, id: value[0].id });
        }
      }
    })

    const tEmptyList = computed({
      get() {
        return [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, set(value: any[]) {
        store.commit('board/add_TicketIdToStep', { stepId: step.value.id, id: value[0].id });
      }
    });

    onMounted(() => {
      // console.log('TheStep onMount');
    })

    onBeforeUnmount(() => {
      //tickets.value = [];
    })

    const onSetExpand = () => {
      emit('set-expand', !(expanded.value));
    }

    let rotatedPreviewOffsetX
    let dragPreviewElement
    let dragPreviewElementInner
    let dragRotateTimer
    const onStart = function (evt) {
      emit('start', { step: { ...step.value }, event: evt })
      console.log('onStart', evt?.item?.dataset?.id, evt, evt.item.getBoundingClientRect(), evt.originalEvent.pageX, step.value)
      const rect = evt?.item?.getBoundingClientRect()
      const pageX = evt.originalEvent.pageX
      dragPreviewElement = document.querySelector('.dragClass')
      dragPreviewElementInner = dragPreviewElement.querySelector('.ticket-inner')
      rotatedPreviewOffsetX = rect && pageX ? - Math.round(pageX - rect.x - rect.width / 2) : 20
    }

    let r1: HTMLElement | null;
    const onMove = (event) => {
      //console.log('onMove', event)
      if (r1 && r1 !== event.related) {
        let e1 = r1.closest('.tickets-column');
        if (e1) {
          e1.classList.remove('drop-container');
        }
      }
      r1 = event.related;
      if (r1) {
        let e1 = r1.closest('.tickets-column');
        if (e1) {
          e1.classList.add('drop-container');
          if (dragPreviewElement && dragPreviewElementInner) {
            if (e1.classList.contains('collapsed')) {
              clearTimeout(dragRotateTimer)
              dragPreviewElement.classList.add('drag-over-collapsed')
              dragPreviewElementInner.style.transform = `rotate(87.5deg) translate(50px, ${rotatedPreviewOffsetX}px)`
            } else {
              dragRotateTimer = setTimeout(() => {
                if (dragPreviewElement && dragPreviewElementInner) {
                  dragPreviewElement.classList.remove('drag-over-collapsed')
                  dragPreviewElementInner.style.transform = 'none'
                }
              }, 200)

            }
          }
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onOrderUpdate = function (event: { moved: { newIndex: unknown; element: any; }; added: { newIndex: unknown; element: any; }; }) {
      console.log('onOrderUpdate ', event);
      let pos: unknown;
      if (event.moved) {
        pos = event.moved.newIndex;
        emit('order-update', { step: { ...step.value }, element: event.moved.element, pos: pos });
      }
      if (event.added) {
        pos = event.added.newIndex;
        emit('order-update', { step: { ...step.value }, element: event.added.element, pos: pos });
      }
      // if (event.removed) {
      //   console.log('onOrderUpdate ', event.removed);
      // }
    }

    const onEnd = function (event) {
      const ticketId = event.item?.dataset?.id
      if (r1) {
        let e1 = r1.closest('.tickets-column');
        if (e1) {
          e1.classList.remove('drop-container');
        }
        r1 = null;
      }
      emit('end');
    }

    const boardLastOpen = store.getters['board/getLastLoadTime']

    const clearTickets = function () {
      store.commit('board/removeAllTickets', step.value.id);
      console.log('clear tickets in step');
    }

    //const refQuickAddTo = ref();
    // const click_quickAddHandler = function () {
    //   emit("quick-add", { stepId: step.value.id, refBtn: refQuickAddTo });
    // }

    //const { enter: onMouseEnter, leave: onMouseLeave } = useElementHover();

    const ticketMenuHandler = (params) => {
      emit('ticketMenu', params)
    }

    return {
      tList,
      tEmptyList,
      boardLastOpen,
      //refQuickAddTo,
      archived_count,
      refArchivedMenuButton,
      refArchivedMenu,
      t,
      onSetExpand,
      onStart,
      onEnd,
      onMove,
      onOrderUpdate,
      clearTickets,
      //click_quickAddHandler,
      // onMouseEnter,
      // onMouseLeave,
      ticketMenuHandler,
      isExistArchivedMenu,
      triggerHandlerArchivedMenu,
    }
  }
})


