
import {defineComponent, onMounted, ref, nextTick} from "vue";
import { useBoardIntersectionObserver } from "@/composables/useBoardIntersectionObserver";

//import {onIdle} from "./idle.js";

export default defineComponent({
  name: "the-lazy",
  props: {
    renderOnIdle: Boolean,
    unrender: Boolean,
    minHeight: Number,
    unrenderDelay: {
      type: Number,
      default: 10000,
    },
  },

  setup(props) {
    const shouldRender = ref(false);
    const targetEl = ref();
    const fixedMinHeight = ref(0);
    //let unrenderTimer;
    let renderTimer;
    let resizeObserverItemUid


    const {
      intersectionObserver,
      addIntersectionObserverItem,
      removeIntersectionObserverItem,
    } = useBoardIntersectionObserver()

    onMounted(async () => {
      await nextTick()
      if (intersectionObserver && targetEl.value) {
        const intersectionHandler = () => {
          //clearTimeout(unrenderTimer);
          renderTimer = setTimeout(
            () => (shouldRender.value = true),
            props.unrender ? 100 : 0
          );
          shouldRender.value = true;
          if (!props.unrender && resizeObserverItemUid) {
            removeIntersectionObserverItem(resizeObserverItemUid)
          }

        }
        resizeObserverItemUid = addIntersectionObserverItem(targetEl.value, intersectionHandler)
      }

    })

    /*
    if (props.renderOnIdle) {
      onIdle(() => {
        shouldRender.value = true;
        if (!props.unrender) {
          stop();
        }
      });
    }
*/
    return {
       targetEl, shouldRender, fixedMinHeight
    }
  }
});
