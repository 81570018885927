import _uniqueId from "lodash/uniqueId"

let intersectionObserver: any = null
const intersectionObserverItems = new Map()

const intersectionHandler = (entries) => {
  console.log('useIntersectionObserver intersectionHandler', entries, intersectionObserverItems)
  for (const entry of entries) {
    intersectionObserverItems.forEach(item => {
      if (item.target === entry.target && entry.isIntersecting) {
        item.callback(entry)
      }
    })
  }

}

const addIntersectionObserverItem = (target, callback) => {
  const uid = _uniqueId('IntersectionObserverItem')
  if (intersectionObserver && target && typeof callback === 'function') {
    intersectionObserverItems.set(uid, {
      uid,
      target,
      callback,
    })
    intersectionObserver.unobserve(target)
    intersectionObserver.observe(target)
  }
  return uid
}

const removeIntersectionObserverItem = (uid) => {
  if (uid) {
    intersectionObserverItems.delete(uid)
    //resizeObserver.unobserve(target)
  }
}

export function useBoardIntersectionObserver() {
  if (!intersectionObserver && 'IntersectionObserver' in self) {
    intersectionObserver = new window['IntersectionObserver'](intersectionHandler, {
      root: document.querySelector('.dashboard-container'),
      threshold: 0
    });
  }

  return {
    intersectionObserver,
    addIntersectionObserverItem,
    removeIntersectionObserverItem,
  }

}
