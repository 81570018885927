import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex overflow-hidden flex-col h-full wrap-container flex-1" }
const _hoisted_2 = {
  key: 0,
  class: "dashboard-tickets"
}
const _hoisted_3 = {
  key: 0,
  class: "tickets-counter-wrap"
}
const _hoisted_4 = { class: "absolute bottom-12 right-16 tickets-counter" }
const _hoisted_5 = { class: "tickets-wrap gap-5 flex-1" }
const _hoisted_6 = {
  key: 1,
  class: "no-tickets-wrap absolute items-center justify-center w-full z-index-9"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_dev_panel = _resolveComponent("the-dev-panel")!
  const _component_the_step = _resolveComponent("the-step")!
  const _component_empty_board_panel = _resolveComponent("empty-board-panel")!
  const _component_menu_ticket = _resolveComponent("menu-ticket")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "refBoard",
    class: _normalizeClass(["flex overflow-hidden flex-1", {'big-board': _ctx.isBigBoard}])
  }, [
    _createVNode(_component_the_dev_panel),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["overflow-auto dashboard-container flex select-none", {
          'top-scroll': _ctx.y>0,
          'demo-dashboard-container': !!_ctx.emptyBoardType
        }]),
        ref: "element"
      }, [
        (_ctx.steps && _ctx.steps.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (!_ctx.ticketsCount==0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createTextVNode("(B2) " + _toDisplayString(_ctx.t('Tickets')) + ": ", 1),
                      _createElementVNode("strong", null, _toDisplayString(_ctx.ticketsCount), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
                  return (_openBlock(), _createBlock(_component_the_step, {
                    key: step.id,
                    step: step.item,
                    "is-empty": !!_ctx.emptyBoardType,
                    expanded: step.expanded,
                    "project-id": _ctx.projectId,
                    ticketViewSettings: _ctx.ticketViewSettings,
                    "is-lazy-on": _ctx.isLazyStepOn,
                    "is-last-step": (index == _ctx.steps.length - 1),
                    "step-index": index,
                    "real-tickets-count": _ctx.stepsTicketsCount[step.id],
                    "quick-add-step-id": _ctx.quickAddStepId,
                    onSetExpand: ($event: any) => (_ctx.expandStep({id: step.id, isExpanded: $event})),
                    onStart: _ctx.onStartDrag,
                    onOrderUpdate: _ctx.onOrderUpdate,
                    onEnd: _ctx.onEndDrag,
                    onTicketMenu: _ctx.ticketMenuHandler
                  }, null, 8, ["step", "is-empty", "expanded", "project-id", "ticketViewSettings", "is-lazy-on", "is-last-step", "step-index", "real-tickets-count", "quick-add-step-id", "onSetExpand", "onStart", "onOrderUpdate", "onEnd", "onTicketMenu"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.emptyBoardType)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_empty_board_panel, { "template-type": _ctx.emptyBoardType }, null, 8, ["template-type"])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    (_ctx.isExistTicketMenu)
      ? (_openBlock(), _createBlock(_component_menu_ticket, {
          key: 0,
          ref: "refTicketMenu",
          "position-target": _ctx.refTicketMenuButton,
          type: "board",
          id: _ctx.ticketMenuParams.id || '',
          name: _ctx.ticketMenuParams.name || '',
          color: _ctx.ticketMenuParams.color || '',
          archived: _ctx.ticketMenuParams.archived || false,
          readonly: _ctx.ticketMenuParams.readonly || false,
          onAction: _ctx.ticketMenuActionHandler,
          onPreviewColor: _ctx.previewTicketColor,
          onChangeColor: _ctx.changeTicketColor,
          onRevertColor: _ctx.revertTicketColor
        }, null, 8, ["position-target", "id", "name", "color", "archived", "readonly", "onAction", "onPreviewColor", "onChangeColor", "onRevertColor"]))
      : _createCommentVNode("", true)
  ], 2))
}